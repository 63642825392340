import React, { useState } from "react";
import "../App.css";
import {
  FormWrapper,
  HeadingContainer,
  Title,
  Paragraph,
  Form,
  ErrorMessage,
  ConfirmationImg,
  WarningIcon
} from '../components/RegistrationForm/RegistrationFormStyleElements'
import { Input, ButtonInput } from '../elements/InputElement'
import { GymClient } from "../components/GymApi.ts"
import { useForm } from "react-hook-form"
import { Button } from "../elements/HeaderButton";
import { formatDateTime } from "../components/utils.js";
import confirm from '../assets/check.png'
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import warning from '../assets/warning.png'

const SubscriptionButton = ({ clickEvent, field, index }) =>
  <Button key={index - field.name} width="90%" maxWidth="auto" margin="20px" type="button" onClick={() => clickEvent(field)}>
    {field.name} <br/> expira la: ({field.endDate})
  </Button>


function HomePage() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState(1);
  const [subscriptions, setSubscriptions] = useState([]);
  const [pin, setPin] = useState("");

  const onSubmit = (clientPINData) => {
    setIsLoading(true);
    const callApi = async (clientPIN) => {
      let client = new GymClient();
      let response = await client.validatePin(clientPIN);
      return response;
    };
    setError("")

    callApi(clientPINData.pin).then(data => {
      if (!data.isSuccess) {
        setIsLoading(false);
        setError(data.message);
        const errorMessage = data.lastStatusSubscription ?? data.message;
        toast.error(errorMessage);
        return error;
      }
      setPin(clientPINData.pin);
      reset();
      setIsLoading(false)

      setSubscriptions(data.subscriptions)
      setStep(2);
      return;

    });
  };

  const chooseSubscriptionType = (subscription) => {
    setIsLoading(true);

    const callApi = async (model) => {
      let client = new GymClient();
      let response = await client.selectedSubscriptionType(model.pin, model.subscription);
      return response;
    };

    callApi({
      pin: pin,
      subscription: subscription
    }).then(data => {
      if (!data.isSuccess) {
        setStep(1);
        setError(`${data.message}`);
        toast.error(data.message);
        setIsLoading(false);
        return error;
      }

      setSubscription(subscription)
      setStep(3);
      setIsLoading(false);
    })
  }

  const setTimeoutForPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 300000);
    return true;
  };

  const handlePinChange = (e) => {
    const newPin = e.target.value;
    localStorage.setItem("pin", newPin);
    setPin(newPin);
  };

  return (
    <>
      <ToastContainer />
      {step === 1 ? (
        !isLoading &&
        <FormWrapper display="flex">
          <HeadingContainer width="auto" display="flex">
            <Title>
              VALIDARE PIN
            </Title>
            <Paragraph>
              Introduceti pinul primit la inregistrare
            </Paragraph>
          </HeadingContainer>
          <div style={{ display: "ruby" }}>
            <Form onSubmit={handleSubmit(onSubmit)}  >
              <Input
                style={{ backgroundColor: "lightgray", color: "black", fontWeight: 400 }}
                type="text"
                {...register("pin", { required: true })}
                name="pin"
                autocomplete="current-password"
                value={localStorage.getItem("pin") || ""}
                onChange={handlePinChange}
              />
              {errors.pin?.type === 'required' && <ErrorMessage>**PIN-ul este obligatoriu</ErrorMessage>}
              <ButtonInput type="submit"
                value="Submit"
                width='170px'
                height='50px'
                font-size='18px'
                tableButton={false} />
              {/* {error && <ErrorMessage maxWidth="400px">{error}</ErrorMessage>} */}
            </Form>
          </div>
        </FormWrapper>) : step === 2 ?
        <>
          {!isLoading &&
            <FormWrapper display="flex">
              <HeadingContainer width="auto" display="flex">
                <Title>
                  Tip antrenament
                </Title>
                <Paragraph>
                  Selectati tipul de antrenament:
                </Paragraph>
              </HeadingContainer>
              <Form onSubmit={handleSubmit(onSubmit)} width="auto">
                {subscriptions.map((field, index) =>
                  <SubscriptionButton clickEvent={chooseSubscriptionType} field={field} index={index} />
                )}
              </Form>
            </FormWrapper>}
        </> :
        <>
          {!isLoading && setTimeoutForPage() && <FormWrapper display="flex">
            <HeadingContainer width="auto" display="flex">
              <Title>
                <ConfirmationImg src={confirm} />
              </Title>
              <Paragraph>
                {`Data: ${formatDateTime(new Date(), false)}`}
              </Paragraph>
              <Paragraph>
                {subscription.name} <br/> expira la: ({subscription.endDate})
              </Paragraph>
              {subscription.numberOfSessions !== "-1" && (
                <>
                  {subscription.numberOfSessions - subscription.userSessions - 1 > 3 ? (
                    <Paragraph>
                      {`Sesiuni ramase: ${subscription.numberOfSessions - subscription.userSessions - 1}`}
                    </Paragraph>
                  ) : (
                    <Paragraph>
                      <WarningIcon src={warning} /> {`Sesiuni ramase: ${subscription.numberOfSessions - subscription.userSessions - 1}`} <WarningIcon src={warning} />
                    </Paragraph>
                  )}
                </>
              )}
            </HeadingContainer>
          </FormWrapper>}
        </>
      }
    </>
  );
}

export default HomePage;
