import React, { useEffect, useState } from "react";
import {
  FormWrapper,
  HeadingContainer,
  Title,
  Paragraph,
  Form,
  Label,
  Select,
  ErrorMessage, ClientData
} from './RegistrationFormStyleElements'
import { Input, DateInput, ButtonInput } from '../../elements/InputElement'
import { useForm, useFieldArray } from "react-hook-form"
import { GymClient } from "../GymApi.ts"
import { useNavigate } from "react-router-dom";
import { Button } from "../../elements/HeaderButton";
import { formatDateTimeExpiration } from "../utils";


function RegistrationForm() {
  const { register, handleSubmit, control, getValues, reset, watch, setValue, formState: { errors } } = useForm();
  const [subscriptionTypeError, setSubscriptionTypeError] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [dateError, setDateError] = useState(false);


  const navigate = useNavigate();
  const divStyle = {
    width: "65%",
    display: "flex",
    "justifyContent": "space-between",
    margin: "15px",
    borderBottom: "1px solid"
  }
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subscriptionTypes',
  });

  const calculateEndDate = (value) => {
    let endDate = new Date(value);
    endDate.setMonth(new Date(value).getMonth() + 1);
    return formatDateTimeExpiration(endDate);
  };

  const subscriptionTypeOptions = [
    { value: 'fitness', name: 'Sala Fitness' },
    { value: 'pt', name: 'Personal Training' },
    { value: 'aero', name: 'Clase Aerobic' },
    { value: 'kickbox', name: 'Clase Kickbox' },
    { value: 'kickboxPersonal', name: 'Clase Kickbox Personal Training' },
  ];

  const mapValueToName = (value) => {
    const option = subscriptionTypeOptions.find((option) => option.value === value);
    return option ? option.name : value;
  };

  useEffect(() => {
    setValue('creationDate', new Date().toISOString());
    setValue("startDate", new Date().toISOString().split('T')[0]);
  }, [setValue]);

  const startDate = watch("startDate");

  useEffect(() => {
    if (startDate) {
      const oneMonthLater = new Date(startDate);
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

      setValue("endDate", oneMonthLater.toISOString().split('T')[0]);
    }
  }, [startDate, setValue]);

  useEffect(() => {
    setValue('active', "N/A");
  }, [setValue]);

  const callApi = (newUser) => {
    try {
      let client = new GymClient();
      return client.addOrUpdateSubscriber(newUser).then((value) => {
        return value;
      });
    } catch (error) {
      if (error && error.status === 401) {
        navigate("/login")
      }
      console.error(error);
    }
  };

  const onSubmit = (newUser) => {

    if (!newUser.subscriptionTypes || newUser.subscriptionTypes.length === 0) {
      setSubscriptionTypeError(true);
      return;
    }

    newUser.subscriptionTypes.map((subscriptionType) => {
      subscriptionType.endDate = new Date(subscriptionType.startDate);
      subscriptionType.endDate.setMonth(new Date(subscriptionType.startDate).getMonth() + 1);
      subscriptionType.endDate = subscriptionType.endDate.toISOString().split('T')[0];
      subscriptionType.creationDate = new Date().toISOString().split('T')[0];
      subscriptionType.isActive = true;
      subscriptionType.userSessions = 0
      return subscriptionType;
    });
    const user = {
      active: newUser.active,
      creationDate: newUser.creationDate,
      email: newUser.email,
      name: newUser.name,
      phoneNumber: newUser.phoneNumber,
      subscriptionTypes: newUser.subscriptionTypes,
      surName: newUser.surName
    }
    callApi(user).then(() => {
      reset();
      navigate("/admin")
    });
  };
  const selectedSubscriptionType = watch("subscriptionType");

  return (
    <FormWrapper>
      <HeadingContainer>
        <Title>
          Adauga Client
        </Title>
        <Paragraph>
          Completati detaliile clientului
        </Paragraph>
      </HeadingContainer>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <ClientData>
          <Label>Nume:*</Label>
          <Input {...register("name", { required: true })} />
          {errors.name?.type === 'required' && <ErrorMessage>**Numele este obligatoriu</ErrorMessage>}

          <Label>Prenume:*</Label>
          <Input {...register("surName", { required: true })} />
          {errors.surName?.type === 'required' && <ErrorMessage>**Prenumele este obligatoriu</ErrorMessage>}

          <Label>Adresa de email:</Label>
          <Input {...register("email")} />

          <Label>Numa de telefon</Label>
          <Input {...register("phoneNumber")} />


          <Title style={{ paddingTop: "20px" }}>
            Detaliile abonamentului
          </Title>

          <Label>Data incepere</Label>
          <DateInput {...register("startDate", { required: true })} type={'date'} />
          {errors.startDate?.type === 'required' && <ErrorMessage>**Data de incepere este obligatorie</ErrorMessage>}

          <Label>Tip abonament</Label>
          {/* <Input {...register("subscriptionType", { required: true })}/> */}
          <Select {...register("subscriptionType", { required: true })}>
            <option value="fitness">Abonament Access Sala Fitness</option>
            <option value="pt">Abonament Personal Training</option>
            <option value="aero">Abonament Clase Aerobic</option>
            <option value="kickbox">Abonament Clase Kickbox</option>
          </Select>
          {errors.subscriptionType?.type === 'required' &&
            <ErrorMessage>**Tipul abonamentului este obligatoriu</ErrorMessage>}


          {selectedSubscriptionType === 'pt' && (
            <>
              <Label>Numar sedinte</Label>
              <Select {...register("sessionNumber", { required: true })}>
                <option value="8">8 sedinte</option>
                <option value="10">10 sedinte</option>
                <option value="12">12 sedinte</option>
                <option value="16">16 sedinte</option>
              </Select>
              {errors.sessionNumber?.type === 'required' && <ErrorMessage>**Numarul de sedinte este obligatoriu
                pentru {mapValueToName(selectedSubscriptionType)}</ErrorMessage>}
            </>
          )}

          {selectedSubscriptionType === 'aero' && (
            <>
              <Label>Numar sedinte</Label>
              <Select {...register("sessionNumber", { required: true })}>
                <option value="8">8 sedinte</option>
                <option value="12">12 sedinte</option>
              </Select>
              {errors.sessionNumber?.type === 'required' &&
                <ErrorMessage>**Numarul de sedinte este obligatoriu</ErrorMessage>}
            </>
          )}

          {selectedSubscriptionType === 'kickbox' && (
            <>
              <Label>Numar sedinte</Label>
              <Select {...register("sessionNumber", { required: true })}>
                <option value="8">8 sedinte</option>
              </Select>
              {errors.sessionNumber?.type === 'required' &&
                <ErrorMessage>**Numarul de sedinte este obligatoriu</ErrorMessage>}
            </>
          )}


          <Button width="250px"
            margin="20px auto;"
            type="button"
            onClick={() => {
              setDuplicateError(false);
              setDateError(false);
              const selectedItems = getValues("subscriptionTypes");
              const selectedType = getValues("subscriptionType");
              if (!startDate) {
                setDateError(true);
                return;
              }
              const alreadyExists = selectedItems.filter(item => item.subscriptionType === selectedType);
              if (alreadyExists && alreadyExists.length !== 0) {
                setDuplicateError(true);
                return;
              }

              let selectedNumberSession = getValues("sessionNumber");
              if (selectedType === "pt" && !selectedNumberSession) {
                selectedNumberSession = "8";
              }
              if (selectedType === "aero" && !selectedNumberSession) {
                selectedNumberSession = "8";
              }
              if (selectedType === "kickbox" && !selectedNumberSession) {
                selectedNumberSession = "8";
              }
              if (!selectedNumberSession) {
                selectedNumberSession = "-1";
              }
              if (selectedType) {
                append({
                  subscriptionType: selectedType,
                  numberOfSessions: selectedNumberSession,
                  startDate: startDate
                });
              }
            }}
          >
            Adauga
          </Button>
          {subscriptionTypeError && <ErrorMessage>**Este necesar cel putin un tip de abonament</ErrorMessage>}
          {duplicateError && <ErrorMessage>**Utilizatorul are deja un abonament activ de acest tip</ErrorMessage>}
          {dateError && <ErrorMessage>**Selectati data de incepere</ErrorMessage>}
        </ClientData>


        <div style={{ width: "100%" }}>
          {/* Display the added subscription types */}
          <div style={{ display: "ruby-text" }}>
            {fields.map((field, index) => (
              <div key={field.id} style={divStyle}>
                <Label style={{ width: "280px", color: 'black', fontWeight: 'bold' }}>{mapValueToName(field.subscriptionType)}</Label>
                <Label style={{ color: 'black', fontWeight: 'bold' }}>{calculateEndDate(field.startDate)}</Label>
                <Button width="100px"
                  margin="10px 0;"
                  type="button" onClick={() => remove(index)}>
                  Sterge
                </Button>
              </div>
            ))}
          </div>

          <div style={{ display: "ruby-text" }}>
            <ButtonInput type="submit"
              value="Salvare"
              width='170px'
              height='50px'
              font-size='18px'
              tableButton={false} />
          </div>
        </div>
      </Form>
    </FormWrapper>
  )
}

export default RegistrationForm