import React from "react";
import "../App.css";
import TableComponent from "../components/TableComponent/TableComponent";

const tableHead = [
  "#",
  "Nume",
  "Prenume",
  "Email",
  "PIN",
  "Actiuni"
];

function AdminHomePage(props) {
 
  return (
    <div>
      {props.users &&
        <TableComponent
          tableHead={tableHead}
          tableData={props.users}
          tableHeader="Clienti"
          hasRedirect={true}
          hasPagination={true}
          pagination={props.pagination}
          hasActionButtons={true}
          onDeleteUser={props.onDeleteUser}
        />

      }

    </div>
  );
}

export default AdminHomePage;
