/* eslint-disable no-restricted-globals */
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig = {
  apiKey: "AIzaSyB65-zwFEPKyIApuvAslGQemkhIFt3nS0o",
  authDomain: "gymbeam-a25eb.firebaseapp.com",
  projectId: "gymbeam-a25eb",
  storageBucket: "gymbeam-a25eb.appspot.com",
  messagingSenderId: "889457058572",
  appId: "1:889457058572:web:cbe75f082f8c5c55a85f29",
  measurementId: "G-8D1TKYQ3P5",
  databaseURL: "https://gymbeam-a25eb-default-rtdb.europe-west1.firebasedatabase.app",
  //databaseURL: "http://127.0.0.1:9000/?ns=gymbeam-a25eb-default-rtdb",
};

export default firebaseConfig;