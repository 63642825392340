import styled from "styled-components";
import { Backgrounds,FontColors, FontSize, FontsFamilies, FontWeights } from "../GlobalStyleConsts";
export const Form = styled.form`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: #8ca5bd;
  color: white;

  /* Change width of the form depending if the bar is opened or not */
  width: 100%;
  /* If bar opened, normal cursor on the whole form. If closed, show pointer on the whole form so user knows he can click to open it */
  cursor: auto;
  padding: 20px;
  height: 20px;
  border-radius: 5px;
  transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  @media screen and (max-width: 760px) {
    display: none;
  }
`;

export const Input = styled.input`
  font-size: 14px;
  line-height: 1;
  background-color: transparent;
  width: 100%;
  margin-left: "20px";
  border: none;
  color: white;
  transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

  &:focus,
  &:active {
    outline: none;
  }

  &::placeholder {
    color: white;
  }
`;

export const Button = styled.button`
  line-height: 1;
  pointer-events: auto;
  cursor: auto;
  background-color: transparent;
  border: none;
  outline: none;
  color:${FontColors.lightGrey};
`;
export const MagnifyImg = styled.img`
height:25px;`;

export const SearchPanel = styled.div`
max-width: 350px;
width: 100%;
display: flex;
justify-content: left;`;

export const Ul = styled.ul`
display: ${(props) => (props.displayItems ? "inline" : "none")};
position:absolute;
top:75px;
border-radius: 5px;
width: 348px;
max-height:400px;
overflow: auto;
padding-inline-start:2px;
margin-top:10px;
background: #8ca5bd;`

export const ResultItems = styled.div`
display: flex;
background: #8ca5bd;
border-radius: 15px;
color: ${FontColors.white};
font-size: ${FontSize.text};
font-family: ${FontsFamilies.rest};
font-weight: ${FontWeights.medium};
width: 90%;
position: relative;
justify-content: space-between;
padding: 3%;
cursor: pointer;
`;

export const TextContainer = styled.div`
  padding: 1px 1vw;
  text-align: right;
`;