import React, {useState, useEffect} from "react";
import "./App.css";
import { Route, Routes, Navigate } from "react-router-dom";
import UserDetails from "./pages/UserDetails";
import AddClient from "./pages/AddClient";
import HomePage from "./pages/HomePage";
import AdminHomePage from "./pages/AdminHomePage";
import Login from "./pages/Login";
import Status from "./pages/Status";
import Header from "./components/Header/Header";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import { GymClient } from "./components/GymApi.ts"
import { useNavigate, useLocation } from "react-router-dom";

function App() {
  const [users, setUsers] = useState([]);
  const [usersPaginated, setUsersPaginated] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, ] = useState(25);
  const [shouldReload, setShouldReload] = useState(false);
  const navigate = useNavigate();
  const [, setUser] = useState( {loggedIn: false});
  const location = useLocation();

  useEffect(() => {
    const client = new GymClient();
    const unsubscribe = client.onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    if(location.pathname !== '/admin') {
      return;
    }
    const callApi = async () => {
      try {
        let client = new GymClient();
        let response = await client.getSubscribers();
        return response;
      } catch (error) {
        if( error && error.status === 401 ) {
            navigate("/login")
        }
        console.error(error);
      }
    };

    callApi().then(data => {
      if(data) {
        setUsers(data);
        setUsersPaginated(data.slice(0, pageSize))
      }
    });
  }, [location.pathname, navigate, shouldReload]);

  const showNext = () => {
    setPage(page+1);

  };

  const showPrevious  = () => {
    setPage(page-1);
  } 

  useEffect(() => {
    setUsersPaginated(users.slice((page-1)*pageSize, page*pageSize));
  }, [page]);

  const onDeleteUserHandler = (userId, pin, userName) => {
    if(location.pathname !== '/admin') {
      return;
    }
    const answer = window.confirm(`Sunteti sigur ca doriti sa stergeti abonatul ${userName} ?`)
    if(!answer) {
      return;
    }
    const callApi = async (userId, pin) => {
      try {
        let client = new GymClient();
        let response = await client.deleteSubscriber(userId, pin);
        return response;
      } catch (error) {
        if( error && error.status === 401 ) {
            navigate("/login")
        }
        console.error(error);
      }
    };

    callApi(userId, pin).then(data => {
      if(data) {
        setShouldReload(!shouldReload);
      }
    });
  }
  const style={
    "marginBottom": "200px"
  }

  return (
      <>
        <Header data={users}/>
        <div style={style}>
          <Routes>
            <Route path="/" element={<HomePage/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/admin" element={<AdminHomePage 
              users={usersPaginated} 
              pagination={{page, showNext, showPrevious, total: users?.length, pageSize: pageSize }}
              onDeleteUser={onDeleteUserHandler}
              />} />
            <Route path="/admin/status" element={<Status />} />
            <Route path="/admin/details/:id" element={<UserDetails/>}/>
            <Route path="/admin/add-client" element={<AddClient/>}/>
            <Route path="*" element={<Navigate replace to="/"/>}/>
          </Routes>
        </div>
        <FooterComponent/>
      </>
  );
}

export default App;
