import React, { useState } from 'react'
import {
  MarketOverviewContainer,
} from '../UserDetailsComponent/UserDetailsComponentStyleElements'
import { Button } from "../../elements/HeaderButton.js";
import { TableHeading, TableHeading2 } from "../TableComponent/TableComponentStyleElements"
import SubscriptionTypeDetails from "../SubscriptionTypeDetails/SubscriptionTypeDetails.js";
import TableComponent from '../TableComponent/TableComponent.js'

const tableHead = [
  "Data intrare",
];

const containerStyle = {
  "width": "90%",
  "margin": "12px auto 0px",
}

const historyStyle = {
  "margin": "12px auto 0px",
  "fontFamily": "Montserrat, sans-serif",
  "color": 'rgb(216, 208, 208)',
}

const HeadStyle = {
  "width": "90%",
  "margin": "12px auto 0px",
  "fontFamily": "Montserrat, sans-serif",
  "color": 'rgb(216, 208, 208)',
  "display": "flex",
  "justifyContent": "space-between",
  "alignItems": "center"
}

const UserHistory = ({ userDetails }) => {
  const [showHistory, setShowHistory] = useState(false);
  const [expandedSubscriptions, setExpandedSubscriptions] = useState([]);

  const toggleSubscription = (subscriptionType) => {
    if (expandedSubscriptions.includes(subscriptionType)) {
      setExpandedSubscriptions(expandedSubscriptions.filter(sub => sub !== subscriptionType));
    } else {
      setExpandedSubscriptions([...expandedSubscriptions, subscriptionType]);
    }
  };

  return (
    <MarketOverviewContainer>
      <div style={HeadStyle}>
        <TableHeading> Istoric abonat</TableHeading>
        <Button type="button" onClick={() => setShowHistory(!showHistory)}>
          {showHistory ? "Ascunde Istoric" : "Afiseaza Istoric"}
        </Button>
      </div>

      {userDetails.subscriptionTypes && showHistory &&
        userDetails.subscriptionTypes.filter((sub) => sub.isActive === false).map((subscription) => {
          const isExpanded = expandedSubscriptions.includes(subscription.id);
          return (
            <div key={`${subscription.id}-${userDetails.id}`} style={containerStyle}>
              {subscription.entries &&
                <>
                  <div style={historyStyle}>
                    {/* TODO here should be toggled after an id */}
                    <div onClick={() => toggleSubscription(subscription.id)} style={{ cursor: 'pointer' }}>
                      <TableHeading2>{subscription.name} (perioada {subscription.startDate} - {subscription.endDate})</TableHeading2>
                    </div>
                  </div>
                  {
                    isExpanded && (
                      <>
                        < SubscriptionTypeDetails details={subscription} margin="0px" isFromHistory={true} />
                        <TableComponent
                          margin="50px auto 100px auto"
                          tableHead={tableHead}
                          tableData={subscription.entries}
                          hasRedirect={false}
                          toggleContent={true}
                        />
                      </>
                    )
                  }


                </>
              }
            </div>)
        })

      }
      {/* 
      <div>
        {userDetails.subscriptionTypes && showHistory && userDetails.subscriptionTypes
          .filter(subscription => !subscription.isActive)
          .map((subscription) => (
            < TableComponent
              key={subscription.name}
              tableHead={[subscription.name]}
              tableData={[]}
              margin="50px auto 100px auto"
              hasRedirect={false}
            />

          ))};


      </div> */}

    </MarketOverviewContainer>
  );
};

export default UserHistory