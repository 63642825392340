export const formatDateTime = (dateTimeString, withText = true, useShortDate = false) => {
    const optionsDate = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Europe/Bucharest' // Set the timeZone to Romanian time
    };
    if(withText) {
        optionsDate.weekday = 'long';
    }

    const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Europe/Bucharest' // Set the timeZone to Romanian time
    };

    const formattedDate = new Date(dateTimeString).toLocaleDateString('ro-RO', optionsDate);
    const formattedTime = new Date(dateTimeString).toLocaleTimeString('ro-RO', optionsTime);

    return withText ? `Inregistrat ${formattedDate}, ora ${formattedTime}` : `${formattedDate}${useShortDate ? '' : ' ' + formattedTime}`;
};


export const formatDateTimeExpiration = (dateTimeString, withText = true) => {
    const optionsDate = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Europe/Bucharest' // Set the timeZone to Romanian time
    };
    if(withText) {
        optionsDate.weekday = 'long';
    }

    const optionsTime = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Europe/Bucharest' // Set the timeZone to Romanian time
    };

    const formattedDate = new Date(dateTimeString).toLocaleDateString('ro-RO', optionsDate);
    const formattedTime = new Date(dateTimeString).toLocaleTimeString('ro-RO', optionsTime);

    return withText ? `Valabil pana ${formattedDate}` : `${formattedDate} ${formattedTime}`;
};
