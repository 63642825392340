import React, { useState, useEffect } from 'react'
import {
    MarketOverviewContainer,
    MarketOverviewHeading,
    UserInfoLine,
    LeftSide,
    RightSide,
} from '../UserDetailsComponent/UserDetailsComponentStyleElements'
import { Button } from "../../elements/HeaderButton";
import { DateInput } from "../../elements/InputElement";
import { Select } from "../RegistrationForm/RegistrationFormStyleElements.js";
import { useForm } from "react-hook-form"
import { GymClient } from "../GymApi.ts"
import { useNavigate, useParams } from "react-router-dom";


const mapValueToName = (value) => {
    const option = subscriptionTypeOptions.find((option) => option.value === value);
    return option ? option.name : value;
};

const subscriptionTypeOptions = [
    { value: 'fitness', name: 'Sala Fitness' },
    { value: 'pt', name: 'Personal Training' },
    { value: 'aero', name: 'Clase Aerobic' },
    { value: 'kickbox', name: 'Clase Kickbox' },
    { value: 'kickboxPersonal', name: 'Clase Kickbox Personal Training' },
];

const headStyle = {
    "width": "100%",
    "margin": "12px auto 0px",
    "fontFamily": "Montserrat, sans-serif",
    "color": 'rgb(216, 208, 208)',
    "display": "flex",
    "justifyContent": "space-between",
    "alignItems": "center"
}


const SubscriptionTypeDetails = ({ details, isFromHistory, onShouldReload }) => {
    const { register, handleSubmit, watch, setValue } = useForm();
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [shouldUpdate, setShouldUpdate] = useState(false);
    const [subscriptionDetails, setSubscriptionDetails] = useState(details);
    const navigate = useNavigate();
    const { id } = useParams();

    const onSubmit = (subscriptionInfo) => {
        const callApi = async (id, detailsToSave) => {
            try {
                let client = new GymClient();
                let response = await client.updateSubscription(id, detailsToSave);
                return response;
            } catch (error) {
                if (error && error.status === 401) {
                    navigate("/login")
                }
                console.error(error);
            }
        };
        const detailsToSave = {
            ...details,
            startDate: subscriptionInfo.startDate,
            endDate: subscriptionDetails.endDate,
            numberOfSessions: subscriptionInfo.numberOfSessions,
        }
        callApi(id, detailsToSave).then(() => {
            setIsInEditMode(false);
            setSubscriptionDetails(detailsToSave);
        });

    };

    const deleteUserSubscription = () => {
        const callApi = async (userId, subscriptionId) => {
            try {
                let client = new GymClient();
                return await client.deleteUserSubscription(userId, subscriptionId);
            } catch (error) {
                if (error && error.status === 401) {
                    navigate("/login")
                }
                console.error(error);
            }
        };
        callApi(id, subscriptionDetails.id).then(() => {
            onShouldReload();
        });
    }


    useEffect(() => {
        setValue('startDate', details.startDate);
        setValue('numberOfSessions', details.numberOfSessions);
    }, [setValue, details, shouldUpdate]);

    const startDate = watch("startDate");
    const numberOfSessions = watch("numberOfSessions");

    useEffect(() => {
        if (startDate) {
            const oneMonthLater = new Date(startDate);
            oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
            setSubscriptionDetails({
                ...details,
                numberOfSessions: subscriptionDetails.numberOfSessions,
                endDate: oneMonthLater.toISOString().split('T')[0]
            })
        }
    }, [startDate, setValue]);

    useEffect(() => {
        if (numberOfSessions) {
            setSubscriptionDetails({
                ...details,
                endDate: subscriptionDetails.endDate,
                numberOfSessions: numberOfSessions
            })
        }
    }, [numberOfSessions, setValue]);

    return (
        <MarketOverviewContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div style={headStyle}>
                    <MarketOverviewHeading>
                        Detalii
                    </MarketOverviewHeading>
                    {!isFromHistory && (!isInEditMode ?
                        <div>
                            <Button
                                key="deleteButton"
                                id="deleteButton"
                                name="deleteButton"
                                type="button"
                                title="Stergere abonament"
                                margin={"0px 30px 0px 0px"}
                                onClick={() => deleteUserSubscription()}>
                                <i className="fa fa-trash"></i>
                            </Button>

                            <Button
                                key="editButton"
                                type="button"
                                title="Editare detalii"
                                onClick={() => { setIsInEditMode(true) }}>
                                <i className="fa fa-pencil"></i>
                            </Button>
                        </div> :

                        <div>
                            <Button
                                key="saveButton"
                                id="saveButton"
                                name="saveButton"
                                margin={"0px 30px 0px 0px"}
                                title="Salvare" type="submit" >
                                <i className="fa fa-save"></i>
                            </Button>
                            <Button background="#e74c3c"
                                key="cancelButton"
                                type="button"
                                title="Anulare"
                                onClick={() => { setIsInEditMode(false); setSubscriptionDetails(details); setShouldUpdate(!shouldUpdate) }}>
                                X
                            </Button>
                        </div>)
                    }


                </div>
                <UserInfoLine>
                    <LeftSide>
                        Status
                    </LeftSide>
                    <RightSide>
                        {subscriptionDetails.isActive ? "Activ" : "Inactiv"}
                    </RightSide>
                </UserInfoLine>
                <UserInfoLine>
                    <LeftSide>
                        Data de inceput
                    </LeftSide>
                    <RightSide>
                        {isInEditMode ?
                            <DateInput width={"130px"} {...register("startDate", { required: true })} type={'date'} /> :
                            subscriptionDetails.startDate
                        }
                    </RightSide>
                </UserInfoLine>
                <UserInfoLine>
                    <LeftSide>
                        Data de sfarsit
                    </LeftSide>
                    <RightSide>
                        {subscriptionDetails.endDate}
                    </RightSide>
                </UserInfoLine>
                {(subscriptionDetails.numberOfSessions !== "-1") &&
                    <UserInfoLine>
                        <LeftSide>Numar sedinte initiale</LeftSide>
                        <RightSide>
                            {isInEditMode ?
                                <>
                                    {
                                        subscriptionDetails.subscriptionType === 'pt' && (
                                            <>
                                                <Select width="130px" {...register("numberOfSessions", { required: true })}>
                                                    <option value="8">8 sedinte</option>
                                                    <option value="10">10 sedinte</option>
                                                    <option value="12">12 sedinte</option>
                                                    <option value="16">16 sedinte</option>
                                                </Select>

                                            </>
                                        )}

                                    {subscriptionDetails.subscriptionType === 'aero' && (
                                        <>
                                            <Select width="130px" {...register("numberOfSessions", { required: true })}>
                                                <option value="8">8 sedinte</option>
                                                <option value="12">12 sedinte</option>
                                            </Select>

                                        </>
                                    )}

                                    {subscriptionDetails.subscriptionType === 'kickbox' && (
                                        <>
                                            <Select width="130px" {...register("numberOfSessions", { required: true })}>
                                                <option value="8">8 sedinte</option>
                                            </Select>

                                        </>
                                    )}
                                </>
                                : subscriptionDetails.numberOfSessions
                            }
                        </RightSide>
                    </UserInfoLine >
                }
                {
                    (subscriptionDetails.numberOfSessions !== "-1") &&
                    <UserInfoLine>
                        <LeftSide>{`Sedinte ramase`}</LeftSide>
                        <RightSide>
                            {(subscriptionDetails.numberOfSessions !== "-1")
                                && `${subscriptionDetails.numberOfSessions - subscriptionDetails.userSessions}`
                            }
                        </RightSide>
                    </UserInfoLine >
                }

                <UserInfoLine>
                    <LeftSide>Tip abonament</LeftSide>
                    <RightSide>{`${mapValueToName(details.subscriptionType)}`}</RightSide>
                </UserInfoLine >
            </form>

        </MarketOverviewContainer>
    );
};

export default SubscriptionTypeDetails
