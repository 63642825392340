import React, { useState } from 'react'
import "../App.css";
import { FormWrapper, HeadingContainer, Title, Paragraph, Form, Label, ErrorMessage } from '../components/RegistrationForm/RegistrationFormStyleElements'
import {Input,ButtonInput} from '../elements/InputElement'
import { GymClient } from "../components/GymApi.ts"
import { useForm } from "react-hook-form"
import configData from "../config.json"
import { useNavigate  } from 'react-router-dom';


const SERVER_URL = configData.REACT_APP_SERVER_URL;

function Login() {
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const onSubmit = (adminData) => {
    const callApi = async (clientPIN) => {
      let client = new GymClient(SERVER_URL);
      let response = await client.login(clientPIN);
      return response;
    };
    callApi(adminData).then(data => {
      if(!data.isSuccess){
        setError(data.message)
        return;
      }
      reset();
      setError('')
      navigate('/admin')
    });
  };

  return (
    <FormWrapper display="flex">
      <HeadingContainer width="auto" display="flex">
        <Title>
          Autentificare
        </Title>
        <Paragraph>
          Administrator 
        </Paragraph>
      </HeadingContainer>
      <div style={{display: "ruby"}}>
        <Form onSubmit={handleSubmit(onSubmit)} width="auto">
          <Label>Email</Label>
          <Input {...register("email", {required: true})} />
          {errors.email?.type === 'required' && <ErrorMessage>**Adresa de email este obligatorie</ErrorMessage>}
          <Label>Parola</Label>
          <Input type="password" {...register("password", {required: true})} />
          {errors.password?.type === 'required' && <ErrorMessage>**Parola este obligatorie</ErrorMessage>}


          <ButtonInput type="submit"
                       value="Submit"
                       width='170px'
                       height='50px'
                       font-size='18px'
                       tableButton={false}/>
          {error && <ErrorMessage>Autentificarea a esuat!</ErrorMessage>}
        </Form>
      </div>
    </FormWrapper>
  );
}

export default Login;
