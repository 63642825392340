import React from 'react'
import {
    FooterWrapper,
    FooterContainer,
    Logo,
    RightsContainer,
    RightsName,
    RightsParagraph,
    SocialLink
} from './FooterComponentStyleElements'

import logo from '../../assets/test_logo.png';
import instagram from '../../assets/social/instagram.svg';
import facebook from '../../assets/social/facebook.png';
import whatsapp from '../../assets/social/whatsapp.png';

import { Link } from 'react-router-dom';

function FooterComponent() {
    return (
        <FooterWrapper>
            {/* <FooterContainer>
                <Link to='/'>
                    <Logo src={logo} />
                </Link>
            </FooterContainer> */}
            <FooterContainer>
                <RightsContainer>
                    <RightsName>© 2024 otygym.ro </RightsName>
                    <RightsParagraph>All rights reserved</RightsParagraph>
                </RightsContainer>
            </FooterContainer>
            <FooterContainer>
                <a href="https://www.instagram.com/oty_gym" target="_blank" rel="noopener noreferrer">
                    <SocialLink src={instagram} />
                </a>
                <a href="https://www.facebook.com/people/Centrul-Sportiv-OTY-GYM/61555283103784" target="_blank" rel="noopener noreferrer">
                    <SocialLink src={facebook} />
                </a>
                <a href="https://wa.me/0731079153" target="_blank" rel="noopener noreferrer">
                    <SocialLink src={whatsapp} />
                </a>
            </FooterContainer>

        </FooterWrapper >
    )
}

export default FooterComponent