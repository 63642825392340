import styled from "styled-components";
import { FontSize, FontColors, FontWeights, FontsFamilies, Borders, Backgrounds } from '../GlobalStyleConsts'

export const UserDetailsContainer = styled.div`
width:80%;
margin:150px auto 0 auto;
border: 0;
border-radius: 15px;
padding:20px;
background: ${Backgrounds.table};
padding: 50px 0;
@media screen and (max-width: 380px) {
 font-size:${FontSize.smallText};
  }`;

export const UserOverviewWrapper = styled.div`
width:90%;
margin:0 auto;`;

export const UserOverviewTopContainer = styled.div`
display:flex;
align-items:center;
${(props)=> props.center ? "justify-content: center" : ""};
@media screen and (max-width: 760px) {
 justify-content:space-between;
 flex-direction:column;
  }`;

export const UserLogo = styled.img`
height:110px;
@media screen and (max-width: 450px) {
 height:80px;
  }`;

export const UserData = styled.div`
margin-left:30px;`;

export const FullName = styled.p`
  color:black;
  font-family:${FontsFamilies.rest};
  font-weight: bold;
  font-size:${FontSize.biggerText};`;

export const Address = styled.p`
  color:black;
  font-family:${FontsFamilies.rest};
  font-weight: bold;
  font-size:${FontSize.biggerText};`;


export const RegistrationDate = styled.p`
  color: black;
  font-family: ${FontsFamilies.rest};
  font-weight: bold;
  font-size: ${FontSize.biggerText};`;

export const UserOverviewBottomContainer = styled.div`
display:flex;
@media screen and (max-width: 760px) {
 justify-content:space-between;
 flex-direction:column;
  }`;

export const SocialContainer = styled.div`
display:flex;
justify-content:space-around;
flex-direction:column;

@media screen and (max-width: 760px) {
 justify-content:space-around;
 flex-direction:row;
  }`;

export const TelegramLogo = styled.img`
height:35px;
margin:15px 0;`;

export const TwitterLogo = styled.img`
height:35px;
margin:15px 0;`;

export const DiscordLogo = styled.img`
height:35px;
margin:15px 0;`;

export const Description = styled.p`
margin-left:110px;
max-width:1000px;
color:black;
font-family:${FontsFamilies.rest};
font-size:${FontSize.text};
@media screen and (max-width: 760px) {
 justify-content:space-around;
 flex-direction:row;
 margin:15px;}`;


export const MarketOverviewContainer = styled.div`
width:90%;
margin:12px auto 0 auto;
font-family:${FontsFamilies.rest};
color:${FontColors.lightGrey};
@media screen and (max-width: 380px) {
    margin:70px auto 0 auto;};`;

export const MarketOverviewHeading = styled.p`
color:black;
font-weight: bold;
font-size:${FontSize.biggerText};
font-weight:${FontWeights.mediumi};`;

export const UserInfoLine = styled.div`
display:flex;
justify-content:space-between; 
border-bottom:1px solid;
border-image:${Borders.reverseGrey};
border-image-slice:1;
@media screen and (max-width: 760px) {
width:100%;}`;

export const LeftSide = styled.p`
  color: black;
  font-weight: bold;
`;

export const RightSide = styled.p`
  color: black;
  font-weight: bold;
`;

export const AnalyticsAndButtonContainer = styled.div`
width:90%;
margin:120px auto 0 auto;
font-family:${FontsFamilies.rest};
color:${FontColors.lightGrey};
display:flex;
justify-content:space-between;
align-items:center;
@media screen and (max-width: 760px) {
width:100%;
flex-direction:column;
  };
 @media screen and (max-width: 380px) {
    margin:70px auto 0 auto;
  }`;

export const Analytics = styled.div`
flex:1;
@media screen and (max-width: 760px) {
width:90%;
margin-bottom:30px;
  }`;
