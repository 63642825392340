import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, MagnifyImg, SearchPanel, Ul, ResultItems, TextContainer } from "./InputBarStyledComponents";
import search from '../../assets/magnify.png'
import { useNavigate  } from 'react-router-dom';
const InputBar = (props) => {
  const [input, setInput] = useState("");
  const [displayItems, setDisplayItems] = useState(false);
  const [options, setOptions] = useState([]);
  const formRef = useRef();
  const inputFocus = useRef();
  const navigate = useNavigate();
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'})}

  useEffect(() => {
    setOptions(props.options)
  }, []);

  useEffect(() => {
    let opt = props.options.filter(e => 
      e.name.toLowerCase().includes(input.toLowerCase()) 
      || e.surName.toLowerCase().includes(input.toLowerCase()))
    setOptions(opt)
  }, [input]);

  const onFormSubmit = (e) => {
    // When form submited, clear input, close the searchbar and do something with input
    e.preventDefault();
    setInput("");
  };

  return (
    options &&
    <div style={{width: "40%"}}>
      <SearchPanel>
        <Form
         onSubmit={onFormSubmit}
          onClick={(e) => {
            e.stopPropagation();
            setDisplayItems(true);
            inputFocus.current.focus();
          }}
          onFocus={() => {
            setDisplayItems(true);
            inputFocus.current.focus();
          }}
          onBlur={() => {
           setDisplayItems(false);
           setOptions([]);
          }}
          ref={formRef}
        >
          <Button>
            <MagnifyImg src={search}/>
          </Button>
            <Input
              onChange={(e) => setInput(e.target.value)}
              ref={inputFocus}
              value={input}
              placeholder="Search Users..."
            />
        </Form>
        <Ul displayItems={displayItems}>
            {options.map((option, index) => {
                return (
                <ResultItems
                key={index}
                onMouseDown  = {(e)=> {
                  navigate('/admin/details/' + option.id);
                  scrollToTop();
                }}
                >
                  <TextContainer>{option.name}</TextContainer>
                  <TextContainer>{option.surName}</TextContainer>
                </ResultItems>
                )})}
        </Ul>
      </SearchPanel>
    </div>
  );
};

export default InputBar;
