import React, { useState, useEffect } from "react";
import { HeaderComp, LogoHeader, HeaderButtonsContainer } from "./HeaderStyledElements.js";
import InputBar from "../InputBar/InputBar";
import { Button } from "../../elements/HeaderButton";
import { Link } from "react-router-dom";
import logo from '../../assets/test_logo.png';
import { useLocation, useNavigate } from "react-router-dom";
import { GymClient } from "../GymApi.ts";


const Header = (props) => {
  const [isInAdminMode, setIsInAdminMode] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsInAdminMode(location.pathname !== '/' && location.pathname !== '/login');
  }, [location]);

  const onlogOut = () => {
    const callApi = async () => {
      let client = new GymClient();
      let response = await client.logout();
      return response;
    };
    callApi().then(() => {
      navigate("/login")
    });
  };
  return (
    <HeaderComp isAdmin={isInAdminMode}>
      <Link to={isInAdminMode ? '/admin' : '/'}>
        {/* <LogoHeader src="../../assets/logoPng.png" /> */}
        <LogoHeader src={logo} />
      </Link>

      {isInAdminMode && <>
        {props.data && <InputBar options={props.data} />}
        <HeaderButtonsContainer>
          <Link to='/admin/status'>
            <Button width='170px' height='50px' font-size='18px' tableButton={false}>Status</Button>
          </Link>
          <Link to='/admin/add-client'>
            <Button width='170px' height='50px' font-size='18px' tableButton={false}>Adauga client</Button>
          </Link>

          <Button title="Deconectare" style={{ marginRight: '10px' }} width='50px' height='auto' font-size='18px' tableButton={false} onClick={onlogOut}><i class="fa fa-power-off"></i></Button>
        </HeaderButtonsContainer>
      </>}

    </HeaderComp>
  );
};

export default Header;