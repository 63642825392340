import React, {useEffect, useState} from "react";
import "../App.css";
import TableComponent from "../components/TableComponent/TableComponent";
import { useNavigate, useLocation } from "react-router-dom";
import { GymClient } from "../components/GymApi.ts"

const tableHead = [
  "#",
  "Nume",
  "Prenume",
  "Ultima Intrare",
  "Abonament",
];

function Status() {
 const [users, setUsers] = useState([]);
 const location = useLocation();
 const navigate = useNavigate();

 useEffect(() => {
  if(location.pathname !== '/admin/status') {
    return;
  }
  const callApi = async () => {
    try {
      let client = new GymClient();
      let response = await client.getActiveUsers();
      return response;
    } catch (error) {
      if( error && error.status === 401 ) {
          navigate("/login")
      }
      console.error(error);
    }
  };
  callApi().then(data => {
    if(data) {
      setUsers(data);
    }
  });
}, [location.pathname, navigate]);

  return (
    <div>
      {users &&
        <TableComponent
          tableHead={tableHead}
          tableData={users}
          tableHeader="Clienti"
          hasRedirect={true}
        />

      }

    </div>
  );
}

export default Status;
