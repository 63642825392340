import styled from "styled-components";
import {
  Backgrounds,
  Borders,
  FontSize,
  FontsFamilies,
} from "../GlobalStyleConsts.js";

export const ComponentContainer = styled.div`
  width: 90%;
  margin: ${(props) => (props.margin ?? "150px auto 100px auto")};
`;
export const TableHeading = styled.p`
  color: black;
  font-family: ${FontsFamilies.rest};
  font-size: ${FontSize.headersAndButtons};
  font-weight: bold;
  text-align: left;
  margin-bottom:20px ;

  @media screen and (max-width: 1100px) {
    font-size: ${FontSize.biggerText};
  };
`;
export const TableHeading2 = styled.p`
  color: black;
  font-family: ${FontsFamilies.rest};
  font-size: ${FontSize.smallerText};
  font-weight: bold;
  text-align: left;
  margin-bottom:20px ;

  @media screen and (max-width: 1100px) {
    font-size: ${FontSize.biggerText};
  };
`;
export const TableWrapper = styled.div`
  border-radius: 15px;  
  margin: 0 auto;

  @keyframes pulsate {
    0%{
      box-shadow:
        0 0 10px ${Borders.solidOrange},
        0 0 20px ${Borders.solidGrey}
    }
  }
  @media screen and (max-width: 1585px) {
    width: auto;
    // overflow-x: scroll;
    white-space: nowrap;
  };
`;

export const Table = styled.table`
  border-radius: 15px;
  background: ${Backgrounds.table};
  color: black;
  border-collapse: collapse;
  font-size: ${FontSize.text};
  font-family: ${FontsFamilies.rest};
  font-weight: bold;
  width: 100%;
  position: relative;
  table-layout: auto;
`;
export const Tbody = styled.tbody`
transition: all 0.2s ease-in-out;`;
export const Thead = styled.thead`
@media screen and (min-width: 1100px) {
  &:hover {
    box-shadow: 0px 3px 4px -6px grey;
    background: #edd3b178;

    Td {
      transition: all 0.3s ease-in-out;
      transform: scale(1.2)
    }
  }
`;
// export const TableHeading = styled.h2`
//   transition: all 0.3s ease-in-out;
//   &:hover {
//     transform: scale(1.1);
//     cursor: pointer;
//   }
// `;

export const Tbr = styled.tr`
  border-top: 1px solid ${Borders.solidGrey};
  height: 50px;

  Td:nth-child(3) {
    position: sticky;
    left: 0px;
    @media screen and (max-width: 1100px) {
      background: ${Backgrounds.table}
    };
  }
;
  @media screen and (min-width: 1100px) {
    &:hover {
      box-shadow: 0px 3px 4px -6px grey;

      Td {
        transition: all 0.3s ease-in-out;
        transform: scale(1.2)
      }
    }
  ;
  }
`;

export const Td = styled.td`
  padding: 15px 4vw;
  text-align: center;
  cursor:pointer;
  @media screen and (max-width: 1100px) {
  padding: 15px 60px;
  };
  @media screen and (max-width: 550px) {
  padding: 15px 30px;
  };
`;

export const Thr = styled.tr`
  border-bottom: 1px solid ${Borders.solidGrey};
  height: 50px;
  th:nth-child(3) {
    position: sticky;
    left: 0;
    @media screen and (max-width: 1100px) {
      background:${Backgrounds.table}
    };
  }
`;

export const ButtonGroup = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
max-width: 200px;
`;

export const PaginationContainer = styled.div`
display: flex;
width: 100%;
align-items: center;
justify-content: space-around;
margin-top: 15px;
`;