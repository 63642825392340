import React, { useEffect, useState } from "react";
import { HeadingContainer, Title, Paragraph, Form, Label, Select, ErrorMessage } from '../RegistrationForm/RegistrationFormStyleElements'
import { DateInput, ButtonInput } from '../../elements/InputElement'
import { useForm, useFieldArray } from "react-hook-form"
import { GymClient } from "../GymApi.ts"
import { useNavigate } from "react-router-dom";
import { Button } from "../../elements/HeaderButton";
import { formatDateTimeExpiration } from '../utils.js'
const divStyle = {
  display: "flex",
  "justifyContent": "space-between",
  margin: "15px",
  width: "80%",
  borderBottom: "1px solid"
};

const containerDivStyle = {
  width: "95%",
  margin: "0px auto",
  fontFamily: "Montserrat, sans-serif"
}


function AddSubscription(props) {

  const { register, handleSubmit, control, getValues, reset, watch, setValue, formState: { errors } } = useForm();
  const [subscriptionTypeError, setSubscriptionTypeError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [dublicateError, setDublicateError] = useState(false);
  const navigate = useNavigate();

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'subscriptionTypes',
  });

  const subscriptionTypeOptions = [
    { value: 'fitness', name: 'Sala Fitness' },
    { value: 'pt', name: 'Personal Training' },
    { value: 'aero', name: 'Clase Aerobic' },
    { value: 'kickbox', name: 'Clase Kickbox' },
    { value: 'kickboxPersonal', name: 'Clase Kickbox Personal Training' },
  ];

  const mapValueToName = (value) => {
    const option = subscriptionTypeOptions.find((option) => option.value === value);
    return option ? option.name : value;
  };

  const calculateEndDate = (value) => {
    let endDate = new Date(value);
    endDate.setMonth(new Date(value).getMonth() + 1);
    return formatDateTimeExpiration(endDate);
  };

  useEffect(() => {
    setValue('creationDate', new Date().toISOString());
    setValue("startDate", new Date().toISOString().split('T')[0]);
  }, [setValue]);

  const startDate = watch("startDate");
  useEffect(() => {
    setDateError(false);
    if (startDate) {
      const oneMonthLater = new Date(startDate);
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

      setValue("endDate", oneMonthLater.toISOString().split('T')[0]);
    }
  }, [startDate, setValue]);

  useEffect(() => {
    setValue('active', "N/A");
  }, [setValue]);


  const onSubmit = (subscriptionDetails) => {
    const callApi = async (user) => {
      try {
        let client = new GymClient();
        let response = await client.addOrUpdateSubscriber(user);
        return response;
      } catch (error) {
        if (error && error.status === 401) {
          navigate("/login")
        }
        console.error(error);
      }

    };

    if (!subscriptionDetails.subscriptionTypes || subscriptionDetails.subscriptionTypes.length === 0) {
      setSubscriptionTypeError(true);
      return;
    }
    subscriptionDetails.subscriptionTypes.map((subscriptionType) => {
      subscriptionType.endDate = new Date(subscriptionType.startDate);
      subscriptionType.endDate.setMonth(new Date(subscriptionType.startDate).getMonth() + 1);
      subscriptionType.endDate = subscriptionType.endDate.toISOString().split('T')[0];
      subscriptionType.creationDate = new Date().toISOString().split('T')[0];
      subscriptionType.isActive = true;
      subscriptionType.userSessions = 0
      return subscriptionType;
    })
    const subscriptionTypes = props.user.subscriptionTypes ?
      [
        ...props.user.subscriptionTypes,
        ...subscriptionDetails.subscriptionTypes
      ] : [
        ...subscriptionDetails.subscriptionTypes
      ]
    const user = {
      ...props.user,
      subscriptionTypes: subscriptionTypes

    }

    callApi(user).then(() => {
      reset();
      props.onSaveDone();
    });
  };

  const selectedSubscriptionType = watch("subscriptionType");

  return (
    <div style={containerDivStyle}>
      <HeadingContainer display="flex">
        <Title>
          Adauga abonament
        </Title>
        <Paragraph>
          Detaliile abonamentului
        </Paragraph>
      </HeadingContainer>

      <Form onSubmit={handleSubmit(onSubmit)} center={true}>

        <Label>Data incepere</Label>
        <DateInput {...register("startDate", { required: true })} type={'date'} />
        {errors.startDate?.type === 'required' && <ErrorMessage>**Data de incepere este obligatorie</ErrorMessage>}

        <Label>Tip abonament</Label>
        {/* <Input {...register("subscriptionType", { required: true })}/> */}
        <Select style={{ backgroundColor: "#8CA5BD", color: "white" }}
          {...register("subscriptionType", { required: true })}>
          <option value="fitness">Abonament Access Sala Fitness</option>
          <option value="pt">Abonament Personal Training</option>
          <option value="aero">Abonament Clase Aerobic</option>
          <option value="kickbox">Abonament Clase Kickbox</option>
        </Select>
        {errors.subscriptionType?.type === 'required' && <ErrorMessage>**Tipul abonamentului este obligatoriu</ErrorMessage>}


        {selectedSubscriptionType === 'pt' && (
          <>
            <Label>Numar sedinte</Label>
            <Select {...register("sessionNumber", { required: true })}>
              <option value="8">8 sedinte</option>
              <option value="10">10 sedinte</option>
              <option value="12">12 sedinte</option>
              <option value="16">16 sedinte</option>
            </Select>
            {errors.sessionNumber?.type === 'required' && <ErrorMessage>**Numarul de sedinte este obligatoriu pentru {mapValueToName(selectedSubscriptionType)}</ErrorMessage>}
          </>
        )}

        {selectedSubscriptionType === 'aero' && (
          <>
            <Label>Numar sedinte</Label>
            <Select {...register("sessionNumber", { required: true })}>
              <option value="8">8 sedinte</option>
              <option value="12">12 sedinte</option>
            </Select>
            {errors.sessionNumber?.type === 'required' && <ErrorMessage>**Numarul de sedinte este obligatoriu</ErrorMessage>}
          </>
        )}
        {selectedSubscriptionType === 'kickbox' && (
          <>
            <Label>Numar sedinte</Label>
            <Select {...register("sessionNumber", { required: true })}>
              <option value="8">8 sedinte</option>
            </Select>
            {errors.sessionNumber?.type === 'required' && <ErrorMessage>**Numarul de sedinte este obligatoriu</ErrorMessage>}
          </>
        )}

        <Button width="350px"
          margin="20px auto;"
          type="button"
          onClick={() => {
            setDublicateError(false);
            setDateError(false);
            const selectedItems = getValues("subscriptionTypes");
            const selectedType = getValues("subscriptionType");
            if (!startDate) {
              setDateError(true);
              return;
            }
            const alreadyExists = selectedItems.filter(item => item.subscriptionType === selectedType);
            if (alreadyExists && alreadyExists.length !== 0) {
              setDublicateError(true);
              return;
            }
            if (props.user.subscriptionTypes) {
              const activeUserSubscription = props.user.subscriptionTypes.filter(item => item.isActive && item.subscriptionType === selectedType);
              if (activeUserSubscription && activeUserSubscription.length !== 0) {
                setDublicateError(true);
                return;
              }
            }
            let selectedNumberSession = getValues("sessionNumber");
            if (selectedType === "pt" && !selectedNumberSession) {
              selectedNumberSession = "8";
            }
            if (selectedType === "aero" && !selectedNumberSession) {
              selectedNumberSession = "8";
            }
            if (selectedType === "kickbox" && !selectedNumberSession) {
              selectedNumberSession = "8";
            }
            if (!selectedNumberSession) {
              selectedNumberSession = "-1";
            }
            if (selectedType) {
              append({ subscriptionType: selectedType, numberOfSessions: selectedNumberSession, startDate: startDate });
              // setValue("subscriptionType", "fitness");
            }
          }}
        >
          Adauga
        </Button>
        {subscriptionTypeError && <ErrorMessage>**Este necesar cel putin un tip de abonament</ErrorMessage>}
        {dateError && <ErrorMessage>**Selectati data de incepere a abonamentului</ErrorMessage>}
        {dublicateError && <ErrorMessage>**Utilizatorul are deja un abonament activ de acest tip</ErrorMessage>}


        {/* Display the added subscription types */}
        {fields.map((field, index) => (
          <div key={field.id} style={divStyle}>
            <Label style={{ width: "280px", color: 'black', fontWeight: 'bold' }}>{mapValueToName(field.subscriptionType)}</Label>
            <Label style={{ color: 'black', fontWeight: 'bold' }}>{calculateEndDate(field.startDate)}</Label>
            <Button width="100px"
              margin="10px 0;" type="button" onClick={() => remove(index)}>
              Sterge
            </Button>
          </div>
        ))}

        <ButtonInput type="submit"
          value="Salvare"
          width='170px'
          height='50px'
          font-size='18px'
          tableButton={false} />
      </Form>

    </div>
  )
}

export default AddSubscription