import React, { useState, useEffect } from 'react'
import RegistrationForm from '../components/RegistrationForm/RegistrationForm'
import { GymClient } from "../components/GymApi.ts"

function AddClient() {
  const [, setUser] = useState( {loggedIn: false});
  
  useEffect(() => {
    const client = new GymClient();
    const unsubscribe = client.onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    }
  }, []);
  return (
    <RegistrationForm/>
  )
}

export default AddClient