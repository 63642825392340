import React, { Fragment, useState } from "react";
import {
  TableHeading,
  Table,
  Thead,
  Tbr,
  Tbody,
  Td,
  TableWrapper,
  ComponentContainer,
  Thr,
  ButtonGroup,
  PaginationContainer
} from "./TableComponentStyleElements";
import { useNavigate } from 'react-router-dom';
import { Button } from "../../elements/HeaderButton";
import { Label } from "../RegistrationForm/RegistrationFormStyleElements";



function TableComponent({ tableData, tableHead, tableHeader, hasRedirect, margin, toggleContent = false, hasPagination = false, pagination, hasActionButtons = false, onDeleteUser }) {
  const [showTable, setShowTable] = useState(toggleContent ? false : true);
  const navigate = useNavigate();

  return (
    <ComponentContainer margin={margin ?? "150px auto 100px auto"} >
      <TableHeading>{tableHeader}</TableHeading >

      <TableWrapper top={true}>
        <Table>
          <Thead>
            <Thr onClick={() => {
              if (toggleContent) {
                setShowTable(!showTable)
              }
            }}>
              {tableHead.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </Thr>
          </Thead>
          {showTable &&
            <Tbody>
              {Object.values(tableData).map((obj) => (
                <Tbr key={obj.id}>
                  {Object.entries(obj)
                    .filter(([key, value]) =>  key !== 'uniq')
                    .map(([key, value], index) => (
                      <Fragment key={index}>
                        {
                          value &&
                            hasRedirect
                            ? <Td onClick={() => navigate('/admin/details/' + obj.id)}>{value}</Td>
                            : <Td>{value}</Td>
                        }
                      </Fragment>
                    ))}
                  {
                    hasActionButtons ?
                      <Td>
                        <Button
                          key="deleteButton"
                          id="deleteButton"
                          name="deleteButton"
                          type="button"
                          title="Stergere client"
                          onClick={() => onDeleteUser(obj.id, obj.pin, `${obj.name} ${obj.surName}` )}>
                          <i className="fa fa-trash"></i>
                        </Button>
                      </Td> : ""
                    }

                </Tbr>
              ))}
            </Tbody>
          }

        </Table>
        {hasPagination && pagination.total && (
          <PaginationContainer>
            <ButtonGroup>
              {
                //show previous button only when we have items
                pagination.page === 1 ? '' :
                  <Button tableData={false} onClick={() => pagination.showPrevious({ item: tableData[0] })}>{"<"}</Button>
              }
              <Label>pagina: {pagination.page} / {Math.ceil(pagination.total / pagination.pageSize)}</Label>
              {
                //show next button only when we have items
                pagination.page === Math.ceil(pagination.total / pagination.pageSize) ? '' :
                  <Button tableData={false} onClick={() => pagination.showNext({ item: tableData[tableData.length - 1] })}>{">"}</Button>
              }
            </ButtonGroup>
          </PaginationContainer>
        )}
      </TableWrapper>
    </ComponentContainer>
  );
}


export default TableComponent;
