import React, { useState, useEffect } from 'react'
import {
    UserDetailsContainer,
    UserOverviewWrapper,
    UserOverviewTopContainer,
    UserData,
    Address,
    FullName,
    RegistrationDate,
} from './UserDetailsComponentStyleElements'
import {
    Title,
    Label,
    ErrorMessage,
    Form,
    FormWrapper
} from '../RegistrationForm/RegistrationFormStyleElements'
import { Input, ButtonInput } from '../../elements/InputElement'
import { Button } from "../../elements/HeaderButton";
import { useForm, } from "react-hook-form"
import { formatDateTime } from '../utils.js'

function UserDetailsComponent(props) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();
    const [isInEditMode, setIsInEditMode] = useState(false);
    let details = props.userDetails;

    useEffect(() => {
        setValue('name', details.name);
        setValue('surName', details.surName);
        setValue('email', details.email);
        setValue('phoneNumber', details.phoneNumber);
    }, [setValue, details]);

    const onSubmit = (details) => {
        props.onSubmit(details).then(() => {
            setIsInEditMode(false);
        })
    }

    return (
        <UserDetailsContainer>
            <UserOverviewWrapper>
                <Title style={{ marginLeft: '30px' }} >Date generale</Title>
                {!isInEditMode && <div style={{ "display": "flex", "justifyContent": "flex-end" }}>
                    <Button
                        key="editUserDetailsButton"
                        type="button"
                        title="Editare detalii"
                        onClick={() => { setIsInEditMode(!isInEditMode) }}>
                        <i className="fa fa-pencil"></i>
                    </Button>
                </div>}

                <UserOverviewTopContainer center={isInEditMode}>
                    <UserData>
                        {
                            isInEditMode
                                ? <FormWrapper padding="0px" margin="none">
                                    <Form margin="none" onSubmit={handleSubmit(onSubmit)}>
                                        <Label>Nume:*</Label>
                                        <Input {...register("name", { required: true })} />
                                        {errors.name?.type === 'required' && <ErrorMessage>**Numele este obligatoriu</ErrorMessage>}

                                        <Label>Prenume:*</Label>
                                        <Input {...register("surName", { required: true })} />
                                        {errors.surName?.type === 'required' && <ErrorMessage>**Prenumele este obligatoriu</ErrorMessage>}

                                        <Label>Adresa de email:</Label>
                                        <Input {...register("email")} />

                                        <Label>Numa de telefon</Label>
                                        <Input {...register("phoneNumber")} />
                                        <div style={{ display: "ruby-text" }}>
                                            <ButtonInput type="submit"
                                                value="Salvare"
                                                width='170px'
                                                height='50px'
                                                font-size='18px'
                                                tableButton={false} />
                                        </div>
                                    </Form>
                                </FormWrapper>
                                : <>
                                    <FullName>
                                        Nume: {details.name}  {details.surName}
                                    </FullName>
                                    <Address>
                                        Mail: {details.email}
                                    </Address>
                                    <FullName>
                                        Telefon: {details.phoneNumber}
                                    </FullName>
                                    <RegistrationDate>
                                        {formatDateTime(details.creationDate)}
                                    </RegistrationDate>
                                    <RegistrationDate>
                                        PIN : {details.pin}
                                    </RegistrationDate>
                                </>
                        }
                    </UserData>
                </UserOverviewTopContainer>
            </UserOverviewWrapper>
            {!isInEditMode ? props.children : <></>}
        </UserDetailsContainer>
    )
}

export default UserDetailsComponent