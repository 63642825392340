import styled from 'styled-components'
import { FontSize, FontColors, FontsFamilies } from '../GlobalStyleConsts'

export const FormWrapper = styled.div`
  margin: ${(props)=> props.margin ?? "80px auto 0px 0px;"}
  padding: ${(props)=> props.padding ?? "50px 0"};
  background: #F8F4EE;
  font-family: ${FontsFamilies.rest};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
`;

export const ClientData = styled.div`
  background: #F8F4EE;
  font-family: ${FontsFamilies.rest};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

`;

export const HeadingContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  ${(props) => props.display === 'flex' ? "display: flex; align-items: center; flex-direction: column;" : ''};
`;

export const Title = styled.p`
    font-size:${FontSize.headersAndButtons};
    text-align: center;
    font-weight:bold;
    color:${FontColors.black};
  
`;

export const Paragraph = styled.p`
    color: black;
    font-weight: bold;
    max-width:700px;
`;

export const Form = styled.form`
  width: 100%;
  ${(props) => props.margin ?? "10px auto 0 auto"};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 300px;
  align-items: center;
  ${(props) => props.center ? "align-items: center;" : ""}
`;

export const Label = styled.label`
    color:black;
    margin:20px 0 10px 0;
`;

export const UploadImg = styled.p`
    color:${FontColors.lightGrey};
    font-size:${FontSize.text};
`;

export const Plus = styled.img`
    padding-top:15px;
    height:40px;
    width:40px;
`;

export const ErrorMessage = styled.p`
    margin-bottom: 0px;
    color:${FontColors.orange};
    font-weight: bold;
    max-width:${(props) => props.maxWidth ?? "300px"};
`;

export const Select = styled.select`
    width: ${props => props.width ?? "350px"};
    height: 40px;
    background-color: #8CA5BD;
    border-radius: 5px;
    border: none;
    padding-left: 5px;
    color: white;
`;

export const ConfirmationImg = styled.img`
  height:128px;`;

export const WarningIcon = styled.img`
  height:24px;`;