import React, { useState, useEffect } from 'react'
import UserDetailsComponent from '../components/UserDetailsComponent/UserDetailsComponent.js'
import TableComponent from '../components/TableComponent/TableComponent.js'
import {
  useParams
} from "react-router-dom";
import { GymClient } from "../components/GymApi.ts"
import { TableHeading } from "../components/TableComponent/TableComponentStyleElements"
import { useNavigate } from "react-router-dom";
import AddSubscription from "../components/AddSubscription/AddSubscription";

import { Button } from "../elements/HeaderButton.js";
import SubscriptionTypeDetails from "../components/SubscriptionTypeDetails/SubscriptionTypeDetails.js";
import UserHistory from "../components/UserHistory/UserHistory.js";

const tableHead = [
  "Data intrare",
];

const HeadStyle = {
  "width": "90%",
  "margin": "12px auto 0px",
  "fontFamily": "Montserrat, sans-serif",
  "color": 'rgb(216, 208, 208)',
  "display": "flex",
  "justifyContent": "space-between",
  "alignItems": "center"
}

const containerStyle = {
  "width": "90%",
  "margin": "12px auto 0px",
}

const buttonStyle = {
  "width": "100%",
  "display": "flex",
  "alignItems": "center",
  "flexDirection": "column",
}

function UserDetails() {
  const [userDetails, setUserDetails] = useState();
  const [userDetailsSubscription, setUserDetailsSubscription] = useState();
  const [showAddSubscription, setShowAddSubscription] = useState();
  const [shouldReload, setShouldReload] = useState(false);
  const [, setUser] = useState({ loggedIn: false });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const client = new GymClient();
    const unsubscribe = client.onAuthStateChange(setUser);
    return () => {
      unsubscribe();
    }
  }, []);


  useEffect(() => {
    const callApi = async (id) => {
      try {
        let client = new GymClient();
        let response = await client.getSubscriberById(id);
        return response;
      } catch (error) {
        if (error && error.status === 401) {
          navigate("/login")
        }
        console.error(error);
      }
    };
    callApi(id).then(data => {
      setUserDetails(data);
      setUserDetailsSubscription(data.subscriptionTypes)
    });
  }, [id, navigate, shouldReload]);

  useEffect(() => {
    setUserDetailsSubscription(userDetails?.subscriptionTypes)
  }, [userDetails]);

  const handleUserDetailsChanged = async (details) => {
    const callApi = async (detailsToSave) => {
      try {
        let client = new GymClient();
        let response = await client.addOrUpdateSubscriber(detailsToSave);
        return response;
      } catch (error) {
        if (error && error.status === 401) {
          navigate("/login")
        }
        console.error(error);
      }
    };
    const detailsToSave = {
      ...userDetails,
      email: details.email,
      name: details.name,
      phoneNumber: details.phoneNumber,
      surName: details.surName,
    }
    return await callApi(detailsToSave).then(() => {
      setShouldReload(!shouldReload);
    });
  }


  return (
    <div>
      {userDetails && <>
        <UserDetailsComponent
          userDetails={userDetails}
          onSubmit={handleUserDetailsChanged}
        >
          {showAddSubscription ?
            <AddSubscription user={userDetails}
              onSaveDone={() => { setShowAddSubscription(false); setShouldReload(!shouldReload) }}
            /> :
            <div style={buttonStyle} >
              <Button type="button" onClick={() => setShowAddSubscription(true)}>
                Adaugare abonament
              </Button>
            </div>

          }
        </UserDetailsComponent>
        {userDetailsSubscription &&
          userDetails.subscriptionTypes.filter((sub) => sub.isActive === true).map((subscription) => {
            return (
              <div key={`${subscription.subscriptionType}-${userDetails.id}`} style={containerStyle}>

                <>
                  <div style={HeadStyle}>
                    <TableHeading>{subscription.name}</TableHeading>
                  </div>
                  <SubscriptionTypeDetails details={subscription} isFromHistory={false} onShouldReload={() => setShouldReload(!shouldReload)} />
                  {subscription.entries &&
                    <TableComponent
                      margin="50px auto 100px auto"
                      tableHead={tableHead}
                      tableData={subscription.entries}
                      hasRedirect={false}
                      toggleContent={true}
                    />
                  }
                </>
              </div>)

          })
        }
        <UserHistory userDetails={userDetails} />
      </>}

    </div>
  )
}

export default UserDetails