
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDatabase, set, ref, get, child, query, orderByKey, limitToLast, orderByChild, limitToFirst, startAfter, endBefore, remove} from "firebase/database";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { formatDateTime } from "./utils";
/// commented 
export interface SubscriberGetAll {
    id?: string;
    name?: string;
    surName?: string;
    email?: string;
    pin?: string;
    uniq?: string;
}

export interface SubscriberDetails {
    id?: string;
    name?: string;
    surName?: string;
    email?: string;
    phoneNumber?: string;
    creationDate: Date,
    startDate: Date,
    // endDate: Date,
    // sessionNumbers: number,
    subscriptionTypes?: any[],
    entries: SubscriberEntries[],
    addedBy?: string | null | undefined,
    modifiedBy?: string | null | undefined,
}

export enum ServerResponseMessage {
    NONE = "",
    NOT_FOUND = "PIN not found",
    BAD_REQUEST = "Bad request",
    INTERNAL = "Internal server error",
    NO_SUBSCRIPTION_FOUND = "No subscription found",
    SUBSCRIPTION_NOT_STARTED = "subscription didn't start",
    SUBSCRIPTION_EXPIRED = "Subscription expired",
    MAX_SESSION_REACHED = "max session reached",
    ALREADY_VALIDATED_TODAY = "Subscription already validated today",
    SUCCESS = "PIN validated"
}

export interface ActiveUser {
    id: string,
    name: string,
    surName: string,
    lastLogin: string,
    lastValidatedSubscription: string,
  }
  

export enum ClientResponseMessage {
    NONE = "",
    INTERNAL_ERROR = "A aparut o eroare la validarea pinului! Contactati administratorul!",
    SUCCESS = "PIN valid",
    PIN_INVALID = "PIN invalid",
    INVALID_SUBSCRIPTION = "Niciun abonament activ.",
    SUBSCRIPTION_NOT_STARTED = "Abonamentul nu a inceput inca.",
    SUBSCRIPTION_EXPIRED = "Abonamentul a expirat.",
    MAX_SESSION_REACHED = "Numarul maxim de intrari a fost atins.",
    ALREADY_VALIDATED_TODAY = "Abonamentul a fost validat astazi."
}


export class GymClient {

    async getSubscribers(): Promise<SubscriberGetAll[] | undefined> {
        try {
            const dbRef = ref(getDatabase());
            const resp = await get(child(dbRef, "subscribers"));
            const subscribers = Object.values(resp.val() || []) as SubscriberGetAll[];

            const subscriberModels = subscribers.map(subscriber => ({
                id: subscriber.id,
                name: subscriber.name,
                surName: subscriber.surName,
                email: subscriber.email,
                pin: subscriber.pin
            })).sort((a, b) => a.name.localeCompare(b.name));
            return subscriberModels;
        } catch (err) {
            console.error(err)
            if ((err as any).message === 'Permission denied') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }

    }

    /// TO DO
    // sort by child is not working
    async getSubscribersPaginated(option : 'next' | 'previous' | null = null, refValue : string | null): Promise<SubscriberGetAll[] | undefined> {
        try {
            const dbRef = ref(getDatabase());
            let queryRef;
            if(!option){
                queryRef = query(child(dbRef,"/subscribers"), orderByChild('uniq'), limitToFirst(5));
            } else if(option === 'next') {
                queryRef = query(dbRef, orderByChild('uniq'), limitToFirst(5), startAfter(refValue));
            } else if(option === 'previous') {
                queryRef = query(dbRef, orderByChild('uniq'), limitToFirst(5), endBefore(refValue));
            }
            const resp = await get(queryRef);
            //const subscribers = Object.values(resp.val() || []) as SubscriberGetAll[];
            const subscribers : SubscriberGetAll[] = [];
            resp.forEach((item) => {
                subscribers.push(item.val());
            });
            const subscriberModels = subscribers.map(subscriber => ({
                id: subscriber.id,
                name: subscriber.name,
                surName: subscriber.surName,
                email: subscriber.email,
                pin: subscriber.pin,
                uniq: subscriber.uniq,
            }));
            return subscriberModels;
        } catch (err) {
            console.error(err)
            if ((err as any).message === 'Permission denied') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }

    }

    async addOrUpdateSubscriber(subscriberDetails: SubscriberDetails): Promise<boolean> {
        try {
            const auth = getAuth();
            let isNew = false;
            if (!subscriberDetails.id) {
                isNew = true;
                subscriberDetails.id = this.generateSubscriptionId();
                subscriberDetails.addedBy = auth.currentUser?.email;
                subscriberDetails.subscriptionTypes = subscriberDetails.subscriptionTypes?.map(e => {
                    e.addedBy = auth.currentUser?.email;
                    e.modifiedBy = auth.currentUser?.email;
                    e.modifiedDate =new Date().toISOString();
                    return e;
                })
            }
            subscriberDetails.modifiedBy = auth.currentUser?.email;
            subscriberDetails.subscriptionTypes = subscriberDetails.subscriptionTypes?.map(e => {
                e.modifiedBy = auth.currentUser?.email;
                e.modifiedDate = new Date().toISOString();
                if(!e.addedBy) {
                    e.addedBy = auth.currentUser?.email;
                }
                e.entries = [];
                return e;
            })
            const db = getDatabase();
            await set(ref(db, "subscribers/" + subscriberDetails.id), subscriberDetails);
            if (isNew) {
                return this.generatePIN().then(async (generatedPin) => {
                    await set(ref(db, "pins/" + generatedPin), subscriberDetails.id);
                    await set(ref(db, "subscribers/" + subscriberDetails.id + "/pin/"), generatedPin);
                    return true;
                })
            }
            return true;
        } catch (err) {
            if ((err as any).message === 'Permission denied' || (err as any).code === 'PERMISSION_DENIED') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }
    }

    async updateSubscription(userId: string, subscriberDetails: any): Promise<boolean> {
        try {
            const auth = getAuth();
            const db = getDatabase();
            subscriberDetails.modifiedBy = auth.currentUser?.email;
            subscriberDetails.modifiedDate = new Date().toISOString();
            subscriberDetails.entries = [];
            
            await set(ref(db, "subscribers/" + userId + "/subscriptionTypes/"+subscriberDetails.id), subscriberDetails);
            return true;
        } catch (err) {
            if ((err as any).message === 'Permission denied' || (err as any).code === 'PERMISSION_DENIED') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }
    }

    async getSubscriberById(subscriberId: string): Promise<any> {
        try {
            const dbRef = ref(getDatabase());
            const userPath = child(child(dbRef, "subscribers"), subscriberId);
            const resp = await get(userPath);
            const userDetails = resp.val();
            const entries = await this.getUserEntries(subscriberId);
            if(userDetails.subscriptionTypes) {
                Object.keys(userDetails.subscriptionTypes).map( async (sub) => {
                    userDetails.subscriptionTypes[sub].name = this.getSubscriptionName(userDetails.subscriptionTypes[sub].subscriptionType);
                    if(!userDetails.subscriptionTypes[sub].id) {
                        userDetails.subscriptionTypes[sub].id = sub;
                        const subscriberPath = child(dbRef, `subscribers/${subscriberId}/subscriptionTypes/${sub}/id`);
                        await set(subscriberPath, sub);
                       
                    }
                    // check if active subscriptions are expired 
                    if(userDetails.subscriptionTypes[sub].isActive){
                        const subEndDate = new Date(userDetails.subscriptionTypes[sub].endDate);
                        const today = new Date();
                        today.setHours(0, 0, 0, 0);
                        if(subEndDate < today ){
                            userDetails.subscriptionTypes[sub].isActive = false;
                            const subscriberActivePath = child(dbRef, `subscribers/${subscriberId}/subscriptionTypes/${sub}/isActive/`);
                            await set(subscriberActivePath, false);

                            const subscriberInactiveReasonPath = child(dbRef, "subscribers/" + subscriberId + "/lastInactiveSubscriptionReason/");
                            const reason = `Ultimul abonament ${ userDetails.subscriptionTypes[sub].name} a devenit inactiv la data ${formatDateTime(subEndDate, false)}, pentru ca abonamentul a expirat.`;
                            await set(subscriberInactiveReasonPath, reason);
                        }
                    }

                    userDetails.subscriptionTypes[sub].entries = entries && entries[sub] ? Object.keys(entries[sub])
                        .map((key) => ({ date: new Date((entries[sub][key])).toLocaleString() })) : [];
                    return sub;
                });
            }
            return userDetails;
        } catch (err) {
            if ((err as any).message === 'Permission denied') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }
    }

    onAuthStateChange(callback) {
        const auth = getAuth();
        return onAuthStateChanged(auth, user => {
            if (user) {
                callback({ loggedIn: true });
            } else {
                callback({ loggedIn: false });
            }
        });
    }

    async login({ email, password }: { email: string; password: string }): Promise<LoginResult> {
        const auth = getAuth();
        let accessToken = "";

        try {
            const result = await signInWithEmailAndPassword(auth, email, password);
            accessToken = (result as any).user.accessToken;

        } catch (err) {
            return {
                isSuccess: false,
                message: (err as any).message
            };
        }

        return {
            isSuccess: true,
            message: accessToken
        };
    }

    async logout(): Promise<boolean> {
        const auth = getAuth();
        return auth.signOut().then(() => {
            return true;
        })
    }

    async validatePin(pin: number): Promise<ValidateResult> {
        try {

            const firebaseSDKfunctions = getFunctions();
            //connectFunctionsEmulator(firebaseSDKfunctions, "127.0.0.1", 5001);
            const validatePIN = httpsCallable<any, ValidateResult>(
                firebaseSDKfunctions,
                "validate"
            );

            return await validatePIN({
                pin: pin,
            }).then((result) => {
                if (!result.data) {
                    return {
                        isSuccess: false,
                        message: ClientResponseMessage.INTERNAL_ERROR,
                    }
                }
                result.data.subscriptions = result.data.subscriptions?.map((sub) => {
                    sub.name = this.getSubscriptionName(sub.subscriptionType)
                    sub.endDate = formatDateTime(sub.endDate, false, true)
                    return { ...sub };
                });
                
                return {
                    isSuccess: true,
                    message: ClientResponseMessage.SUCCESS,
                    subscriptions: result.data.subscriptions,
                }
            })
        } catch (err) {
            return {
                isSuccess: false,
                message: this.getErrorMessage(err.message),
                lastStatusSubscription: err.details.lastStatusSubscription,
            }
        }
    }

    async selectedSubscriptionType(pin: number, subscription: any) {
        try {
            const firebaseSDKfunctions = getFunctions();
            //connectFunctionsEmulator(firebaseSDKfunctions, "127.0.0.1", 5001);
            const logEntryByPinAndSubscriptionRef = httpsCallable<any, LogResult>(
                firebaseSDKfunctions,
                "logEntryByPinAndSubscriptionRef"
            );

            return await logEntryByPinAndSubscriptionRef({
                pin: pin,
                subscription: subscription,
            }).then((result) => {
                if (!result.data) {
                    return {
                        isSuccess: false,
                        message: ClientResponseMessage.INTERNAL_ERROR,
                    }
                }
                result.data.subscriptions.name = this.getSubscriptionName(result.data.subscriptions.subscriptionType);
                return {
                    isSuccess: true,
                    message: ClientResponseMessage.SUCCESS,
                    subscriptions: result.data.subscriptions,
                }
            });
        } catch (err) {
            console.error(err.message);
            return {
                isSuccess: false,
                message: this.getErrorMessage(err.message),
            }
        }
    }

    async getActiveUsers() {
        try {
            const firebaseSDKfunctions = getFunctions();
            //connectFunctionsEmulator(firebaseSDKfunctions, "127.0.0.1", 5001);
            const getActiveUsersFunction = httpsCallable<any, ActiveUser[]>(
                firebaseSDKfunctions,
                "getActiveUsers"
            );

            return await getActiveUsersFunction().then((result) => {
                return result.data.sort((a, b) => new Date(b.lastLogin) - new Date(a.lastLogin));
            });
        } catch (err) {
            console.error(err.message);
            return [];
        }
    }

    async deleteUserSubscription(userId :string, subscriptionId: string) {
        try {
            const dbRef = ref(getDatabase());
            const subscriptionActivePath = child(dbRef, `subscribers/${userId}/subscriptionTypes/${subscriptionId}/isActive/`);
            await set(subscriptionActivePath, false);
            const subscriptionStatusPath = child(dbRef, `subscribers/${userId}/subscriptionTypes/${subscriptionId}/status/`);
            await set(subscriptionStatusPath, "deleted");
            return true;
        } catch (err) {
            if ((err as any).message === 'Permission denied') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }
    }

    async deleteSubscriber(userId :string, pin: string) {
        try {
            const dbRef = ref(getDatabase());
            const subscriberPath = child(dbRef, `subscribers/${userId}`);
            const pinPath = child(dbRef, `pins/${pin}`);
            const entriesPath = child(dbRef, `entries/${userId}`);

            await remove(pinPath);
            await remove(entriesPath);
            await remove(subscriberPath);
            return true;
        } catch (err) {
            if ((err as any).message === 'Permission denied') {
                return throwException((err as any).message, 401, '', {});
            }
            return throwException((err as any).message, 500, 'Internal server error', {});
        }
    }
   

    protected generateSubscriptionId = () => {
        const now = new Date().getTime().toString();
        const randomInt = this.getRandomInt(1, 20);

        return `${now}${randomInt}`;
    };
    protected getRandomInt = (min: number, max: number) => {
        return Math.round(Math.random() * (max - min)) + min;
    };

    protected getSubscriptionName = (subscriptionType: string) => {
        switch (subscriptionType) {
            case "fitness":
                return "Sala Fitness";
            case "pt":
                return "Personal Training";
            case "aero":
                return "Clase Aerobic";
            case "kickbox":
                return "Clase Kickbox";
        }
    };

    protected getErrorMessage = (serverError: ServerResponseMessage) => {
        switch (serverError) {
            case ServerResponseMessage.NOT_FOUND:
            case ServerResponseMessage.BAD_REQUEST:
                return ClientResponseMessage.PIN_INVALID;
            case ServerResponseMessage.INTERNAL:
                return ClientResponseMessage.INTERNAL_ERROR;
            case ServerResponseMessage.NO_SUBSCRIPTION_FOUND:
                return ClientResponseMessage.INVALID_SUBSCRIPTION
            case ServerResponseMessage.SUBSCRIPTION_NOT_STARTED:
                return ClientResponseMessage.SUBSCRIPTION_NOT_STARTED
            case ServerResponseMessage.SUBSCRIPTION_EXPIRED:
                return ClientResponseMessage.SUBSCRIPTION_EXPIRED
            case ServerResponseMessage.MAX_SESSION_REACHED:
                return ClientResponseMessage.MAX_SESSION_REACHED;
            case ServerResponseMessage.ALREADY_VALIDATED_TODAY:
                return ClientResponseMessage.ALREADY_VALIDATED_TODAY;
            case ServerResponseMessage.SUCCESS:
                return ClientResponseMessage.SUCCESS;
            default:
                return ClientResponseMessage.NONE;
        }
    };

    protected generatePIN = async () => {
        const db = getDatabase();
        const data = await get(query(ref(db, 'pins/'), orderByKey(), limitToLast(1)));
        const randomString = this.randomString(3);
        if (!data.val()) {
            return "0001" + randomString;
        }
        var lastPin = Object.keys(data.val())[0];
        if (!lastPin) {
            return "0001" + randomString;
        }
        var lastNumber = parseInt(lastPin.slice(0, -3));
        return ('0000' + ++lastNumber).substr(-4) + randomString;
    };

    protected randomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    protected getUserEntries = async (userId) => {
        try {
            const dbRef = ref(getDatabase());
            const entriesPath = child(child(dbRef, "entries"), userId);
            const resp = await get(entriesPath);
            const userEntries = resp.val();
            return userEntries;
        } catch (err) {
            console.error("Unable to get ser entries", err.message);
        }
    }

}
export interface LoginResult {
    isSuccess: boolean,
    message: string,
}


export interface ValidateResult {
    isSuccess: boolean,
    message: ClientResponseMessage,
    lastStatusSubscription?: string,
    subscriptions?: any[]
}

export interface LogResult {
    isSuccess: boolean,
    message: string,
    subscriptions: any
}



export interface SubscriberEntries {
    date: Date;
    subscriptionName: string;
}

export enum SubscriptionType {
    None = 0,
    Monthly,
    Session,
}


export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new ApiException(message, status, response, headers, null);
}

