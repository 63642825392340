import styled from "styled-components";

export const HeaderComp = styled.div`
  max-height: 185px;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content:  ${(props) => (props.isAdmin ? "space-between" : "space-around")};
  top: 0;
  left: 0%;
  right: 0%;
  z-index: 10;
  background-color: white;
  padding: 0 20px 0 20px;
  border-radius: 0 0 0px 0px;
`;

export const LogoHeader = styled.img`
  width: 100px;
  padding: 0px;

  @media screen and (max-width: 760px) {
    width: 100px;
  }
`;

export const HeaderButtonsContainer = styled.div`
  max-width: 530px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
